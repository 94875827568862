import React from 'react';
import Gallery from 'react-photo-gallery'
import './style.css';
import img from '../../images/clinica/letrero.jpeg'
import img2 from '../../images/clinica/camilla2.jpeg'
import img3 from '../../images/clinica/puertabaño.jpeg'
import img4 from '../../images/clinica/mesa.jpeg'
import img5 from '../../images/clinica/logosbaños.jpeg'
import img6 from '../../images/clinica/camilla.jpeg'
import img7 from '../../images/clinica/entrada.jpeg'
import img8 from '../../images/clinica/material.jpeg'
import img9 from '../../images/clinica/entrada1.jpg'
import img10 from '../../images/clinica/entrada2.jpg'

const Clinica = () => {
  let id = 0
  const photos = [
    {
      key: id++,
      src: img,
      width: 4,
      height: 3,
    },
    {
      key: id++,
      src: img2,
      width: 4,
      height: 3
    },
    {
      key: id++,
      src: img3,
      width: 0.5,
      height: 0.5
    },
    {
      key: id++,
      src: img4,
      width: 1,
      height: 1
    },
    {
      key: id++,
      src: img5,
      width: 3,
      height: 2
    },
    {
      key: id++,
      src: img8,
      width: 1,
      height: 0.8
    },
    {
      key: id++,
      src: img6,
      width: 1,
      height: 1
    },
    {
      key: id++,
      src: img7,
      width: 1,
      height: 1
    },
    {
      key: id++,
      src: img9,
      width: 1,
      height: 1
    },
    {
      key: id++,
      src: img10,
      width: 1,
      height: 1
    },

  ];
  return (
    <>
      <div style={{ padding: '20px', }}>
        <p style={{
          fontFamily: 'caviar',
          fontSize: '25px',
          textAlign: 'center',
          marginLeft: '2rem',
          color: 'black'
        }}><span style = {{color:'#2BADAD' , fontWeight:'bold' , textAlign:'justify'}}>Fisioterapia Pinseque</span> nace con la idea de poder ofrecer el mejor servicio de fisioterapia a sus pacientes. Para ello contamos con unas instalaciones
          que favorecen un entorno cálido, agradable y confortable,
            ideal para el buen desarrollo de nuestros servicios y actividades.</p>
        {/* <p style={{
          fontFamily: 'caviar',
          fontSize: '25px',
          textAlign: 'center',
          marginLeft: '2rem',
          color: 'black'
        }}>Nuestra misión es poder saber si los síntomas que presentan nuestros pacientes, pueden ser susceptibles de tratados o no por Fisioterapia, y una vez decidido
          esto y según las respuestas, plantear que acciones llevaremos a cabo en o con el paciente, activas o pasivas, ya que podremos más o menos saber que provocarán,
              disminuirán, modificarán o eliminarán estos síntomas y así ir orientando el tratamiento, haciendo al paciente partícipe de la resolución de su problemática.</p> */}
      </div>
      <Gallery photos={photos} direction="column" />
    </>
  )
}

export default Clinica