export const fbConfig = {
    ref: 'phrases',
    app: {
    apiKey: "AIzaSyAyU0Dl11X-fM9kZkdl2_lqN5QKCmic4qs",
    authDomain: "contacto-form-fba40.firebaseapp.com",
    databaseURL: "https://contacto-form-fba40.firebaseio.com",
    projectId: "contacto-form-fba40",
    storageBucket: "contacto-form-fba40.appspot.com",
    messagingSenderId: "293156019091",
    appId: "1:293156019091:web:665df479dcfa144b226666",
    measurementId: "G-6FGQ0WGW1S"
    }
}